import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Card from "@mui/material/Card"
import MDBox from "../../components/MDBox"
import MDTypography from "../../components/MDTypography"
import MDInput from "../../components/MDInput"
import MDButton from "../../components/MDButton"
import { supabase } from "../../supabaseClient"
import { useToast } from "@dalgu/react-toast"
import logo from "../../logos"
import trackEvent from "../../components/context"

function LoginPage({ updateSessionFunction }) {
  const navigate = useNavigate()
  const { showToast } = useToast()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase())
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }
  async function updateManifest() {
    //Get the content from localStorage
    const authToken = localStorage.getItem("sb-lcxmxvpzbenzvgpxnent-auth-token")
    // Stringify the JSON object
    const jsonString = JSON.stringify(authToken)

    // Encode the JSON string as Uint8Array
    const encoder = new TextEncoder()
    const jsonDataEncoded = encoder.encode(jsonString)

    // Convert the binary data to a Base64 URL-safe string
    const base64Data = btoa(String.fromCharCode.apply(null, jsonDataEncoded)).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "")

    document.querySelector("#manifest-placeholder").setAttribute("href", "/manifest.json?a=" + base64Data)
  }
  const handleLogin = async event => {
    event.preventDefault()
    setLoading(true)
    const { data: profile } = await supabase.from("profiles_view").select("email").eq("username", email).single()
    let { data, error } = await supabase.auth.signInWithPassword({
      email: profile?.email || email,
      password: password
    })
    if (error) {
      showToast(error.message)
    } else {
      updateSessionFunction(data.user)
      trackEvent("Login")
      updateManifest().then(() => navigate("/"))
    }
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  const resetPassword = async event => {
    event.preventDefault()
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://mydigitalestate.app/settings/?editmode"
    })
    if (error) {
      showToast(error.message)
    } else {
      showToast("Password reset email has been sent.")
    }
  }

  // DO NOT DELETE
  // const handleAppleLogin = async () => {
  //   const { data, error } = await supabase.auth.signInWithOAuth({
  //     provider: "apple"
  //   })

  //   if (error) {
  //     console.error("Apple login error:", error.message)
  //     return
  //   }
  // }
  return (
    <MDBox sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "center" }}>
      <MDBox sx={{ display: "flex", mx: "auto", maxWidth: "400px", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
        <Card
          sx={{
            background: "rgb(255 255 255 / 75%)",
            width: "100%"
          }}>
          <MDBox px={2} pt={2}>
            {logo}
          </MDBox>
          {/* <p>Total Logins: {loginCount}</p> */}
          <MDBox mt={0} pt={4} pb={3} px={3}>
            <MDBox className="loginForm" component="form" role="form" onSubmit={handleLogin}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  autoComplete="username"
                  label="Email or Username"
                  data-test-id="input-email"
                  fullWidth
                  autoFocus
                  onChange={handleEmailChange}
                  value={email}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  data-test-id="input-password"
                  fullWidth
                  onChange={handlePasswordChange}
                  value={password || undefined}
                  autoComplete="current-password"
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" data-test-id="btn-login" fullWidth type="submit" disabled={!email || !password || loading}>
                  Login
                </MDButton>
              </MDBox>
              {/* DO NOT DELETE <MDBox mt={3} mb={1} textAlign="center">
              <MDButton variant="gradient" color="info" fullWidth onClick={handleAppleLogin}>
                Login with Apple
              </MDButton>
            </MDBox> */}
              <MDBox pt={3} textAlign="center" color="#3b94ee">
                <MDTypography variant="button" sx={{ color: "#7b809a" }}>
                  Forgot password?{" "}
                </MDTypography>
                <MDTypography
                  component={Link}
                  onClick={resetPassword}
                  data-test-id="btn-forgot-password"
                  variant="button"
                  sx={{ cursor: "pointer" }}
                  fontWeight="medium">
                  Click here
                </MDTypography>
              </MDBox>
              <MDBox mb={1} textAlign="center" color="#3b94ee">
                <MDTypography variant="button" sx={{ color: "#7b809a" }}>
                  Don't have an account yet?{" "}
                </MDTypography>
                <MDTypography
                  component={Link}
                  to="/register"
                  variant="button"
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  data-test-id="btn-register"
                  fontWeight="medium">
                  Register here
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  )
}

export default LoginPage
