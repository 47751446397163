import { Link } from "react-router-dom"
import "./card.css"
import "./link.css"
import { useSpring, animated } from "@react-spring/web"
import rightArrow from "../../../icons/rightArrow"

const Card = ({ scrollPosition, isJoinTheBetaBigger }) => {
  const { borderRadius } = useSpring({
    borderRadius: `${3 - (scrollPosition / window.innerHeight) * 3}vh`
  })

  return (
    <animated.div
      class={"card"}
      style={{
        borderRadius
      }}
      is="webview">
      <Link to="/login" style={{ pointerEvents: isJoinTheBetaBigger && "none" }}>
        or login here {rightArrow}
      </Link>
      <texts is="webview">
        <h1>We create amazing opportunities in the real estate market.</h1>
        <p>At MyDigitalEstate, we're committed to revolutionising the real estate investment experience.</p>
      </texts>
      <Link className="spacer" to="/login">
        or login here
      </Link>
    </animated.div>
  )
}
export default Card
