import "./index.css"
import React, { useEffect, useState } from "react"

import poster from "./components/poster"
import Card from "./components/card"
import logo from "./components/logo"
import JoinTheBetaButton from "./components/join-the-beta-button"
import FooterLandingPage from "./components/footerlandingpage"
import LoginWhiteVersion from "./components/loginwhiteversion"
import InstallLandingPage from "./components/installLandingPage"
import EnableNotificationsLandingPage from "./components/enableNotificationsLandingPage"
import LoginAccountLandingPage from "./components/LoginAccountLandingPage"
import ProgressPage from "./components/progressPage"
import OpacityLandingPage from "./components/opacityLandingPage"

function AboutPage({ inviter, updateSessionFunction }) {
  const aboutRef = React.useRef()
  const [isJoinTheBetaBigger, setIsJoinTheBetaBigger] = useState(false)
  const [isfooterOpened, setIsFooterOpened] = useState(false)

  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    //we update scroll state only in the first 100vh of the page because that's where it is needed for the card borders animation,
    // we stop updating it after that so that components don't get rerendered unnecessarily
    const handleScroll = () => aboutRef.current.scrollTop < window.innerHeight && setScrollPosition(aboutRef.current.scrollTop)
    aboutRef.current.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [aboutRef])
  const handleJoinTheBetaClick = () => {
    setIsJoinTheBetaBigger(true)
    setIsFooterOpened(true)
  }
  const handleLogoClick = () => {
    setIsFooterOpened(false)
    setTimeout(
      () =>
        setIsJoinTheBetaBigger(prev => {
          if (!prev) window.location.href = "#firstpage"
          return false
        }),
      isJoinTheBetaBigger ? 200 : 0
    )
  }
  return (
    <>
      <about ref={aboutRef} is="webview" style={{ overflow: isJoinTheBetaBigger && "hidden" }}>
        <meta name="theme-color" content="#007AFF"></meta>
        <style>
          {`html, body, #root {
            background-color: #007AFF;
        }`}
        </style>
        <firstpage is="webview" id="firstpage">
          <logobackground is="webview" />

          <logo is="webview" onClick={handleLogoClick} class={scrollPosition > window.innerHeight - 10 && "noshadow"}>
            {logo}
          </logo>
          {poster}
          <JoinTheBetaButton onClick={handleJoinTheBetaClick} opened={isJoinTheBetaBigger} />
          <Card scrollPosition={scrollPosition} isJoinTheBetaBigger={isJoinTheBetaBigger} />
        </firstpage>
        <FooterLandingPage opened={isfooterOpened} isJoinTheBetaBigger={isJoinTheBetaBigger} updateSessionFunction={updateSessionFunction} />
      </about>
    </>
  )
}

export default AboutPage
