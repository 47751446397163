import logomark from "../images/logo/logomark.svg"
import logotext from "../images/logo/logotext.svg"
import "./logo.css"

const logo = (
  <>
    <img className="logomark" src={logomark} alt="MyDigitalEstate Logo Mark" />
    <img className="logotext" src={logotext} alt="MyDigitalEstate Logo Text" />
  </>
)
export default logo
