import React, { useEffect, useState } from "react"
import { animated, useTrail } from "@react-spring/web"
import { Checkbox, FormControlLabel, ThemeProvider } from "@mui/material"
import theme from "../../../asset/theme"
import { TermsAndConditions } from "../../register/TermsAndConditions"
import TermsAndConditionsDialog from "../../register/TermsAndConditionDialog"
import MDInput from "../../../components/MDInput"
import MDTypography from "../../../components/MDTypography"
import MDButton from "../../../components/MDButton"
import Footer from "../../../components/Footer"
import "./footerlandingpage.css"
import { useToast } from "@dalgu/react-toast"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../../supabaseClient"

const FooterLandingPage = ({ opened, inviter = "andreafuturi", updateSessionFunction, isJoinTheBetaBigger }) => {
  const [isInView, setIsInView] = useState(false)
  const footerRef = React.useRef()
  const { showToast } = useToast()
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [invited_by, setInviter] = useState(inviter)
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)

  const handleClickOpenTerms = () => {
    setShowTerms(prevState => {
      return true
    })
  }

  const handleCloseTerms = () => {
    setShowTerms(prevState => {
      return false
    })
  }

  const handleDialogAcceptTerms = () => {
    setAcceptTerms(true)
    setShowTerms(false)
  }
  const handleAcceptTerms = () => {
    setAcceptTerms(!acceptTerms)
  }

  useEffect(() => {
    //when footer is in page makes form animation starts
    const footer = footerRef.current
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsInView(entry.isIntersecting)
      })
    })
    observer.observe(footer)
    return () => {
      observer.disconnect()
    }
  }, [footerRef])
  useEffect(() => {
    //when footer is in page focus the mail input
    if (opened) setTimeout(() => footerRef.current.querySelector(".signup-input-email input").focus(), 1000)
  }, [opened, footerRef])

  const elements = [
    <h1>Sign up and be part of the beta program.</h1>,
    <p>Let’s start with your account details, we will give you a fake balance and a quick app tour you can skip at any time. No credit card required.</p>,
    <MDInput
      className={`signup-input-email`}
      sx={{
        backgroundColor: "#0d6dd9",
        borderRadius: "10px"
      }}
      type="email"
      label="Email address"
      autoComplete="email"
      fullWidth
      onChange={event => setEmail(event.target.value.trim())}
      value={email}
    />,
    <MDInput
      sx={{
        backgroundColor: "#0d6dd9",
        borderRadius: "10px"
      }}
      type="text"
      label="Username"
      fullWidth
      autoComplete="nickname"
      onChange={event => {
        const input = event.target.value.toLowerCase().trim()
        const regularExpression = /^[a-z0-9]+$/
        if (regularExpression.test(input) || input === "") {
          setName(input)
        }
      }}
      value={name}
    />,
    <MDInput
      sx={{
        backgroundColor: "#0d6dd9",
        borderRadius: "10px"
      }}
      type="password"
      label="Password"
      data-test-id="input-password"
      fullWidth
      autoComplete="new-password"
      onChange={event => setPassword(event.target.value)}
      value={password}
    />,
    <FormControlLabel
      onChange={handleAcceptTerms}
      control={<Checkbox checked={acceptTerms} className="custom-checkbox" />}
      sx={{ display: "flex", textAlign: "center" }}
      label={
        <>
          <MDTypography variant="button" fontWeight="regular" color="text" sx={{ cursor: "pointer", userSelect: "none", color: "#fff" }}>
            &nbsp;&nbsp;I accept the&nbsp;
          </MDTypography>
          <TermsAndConditions onClick={handleClickOpenTerms} fontWeight="600" fontSize="0.875rem" lineHeight="14px" terms={"terms"} termsColor="#344767" />
        </>
      }
    />,
    <MDButton
      variant="gradient"
      data-test-id="btn-login"
      type="submit"
      sx={{
        textTransform: "none",
        px: "50px",
        borderRadius: "4.375rem",
        fontWeight: 500,
        fontSize: "120%",
        lineHeight: "14px"
      }}
      disabled={!acceptTerms || !name || !email || !invited_by || !password || loading}
      className="textButton">
      Sign up
    </MDButton>,
    <Footer nameAppColor={"#fefefe"} developerName={"Futuri ENT"} color="#fff" display="none" backgroundColor="trasparent" opacity="1" />
  ]
  const trail = useTrail(elements.length, {
    transform: opened ? "translateY(-103svh)" : `translateY(${isInView ? 0 : "100"}svh)`,
    reverse: opened ? false : isInView ? false : true,
    config: { mass: 1, friction: 30, tension: 500 }
  })
  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const { data: usernames, error: usernameError } = await supabase.from("profiles_view").select("username")

    const username = usernames.map(user => user.username)
    if (username?.includes(name)) {
      showToast("Username already exists. Please choose a different username.")
      return
    }

    if (invited_by !== "" && !username?.includes(invited_by)) {
      showToast("Please get a valid invitation.")
      return
    } else {
      setInviter(invited_by)
    }

    if (usernameError) {
      showToast(usernameError.message)
      return
    }

    const { data: emailData, error: emailError } = await supabase.from("profiles_view").select("email").eq("email", email)

    if (emailError) {
      showToast(emailError.message)
      return
    }

    if (emailData?.length > 0) {
      showToast("Email already exists. Please choose a different email.")
      return
    }
    const { error: registrationError } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: { username: name, invited_by: invited_by || inviter, firstroute: "/tutorial/1" }
      }
    })
    //delay end of loading to prevent double click
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    if (!registrationError) {
      let { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password
      })
      if (error) {
        showToast(error.message)
      } else {
        updateSessionFunction(data.user)
        navigate("/config")
      }
    }
    if (registrationError !== null) {
      if (registrationError.message === 'new row for relation "profiles" violates check constraint "username_blacklist_constraint"') {
        showToast("The username you are trying to use is not available. Please try another one!")
      } else if (registrationError.message === 'new row for relation "profiles" violates check constraint "username_constraint"') {
        showToast("Username length cannot be longer than 20 characters")
      } else {
        showToast(registrationError.message)
      }
    }
  }
  return (
    <footer ref={footerRef} style={{ overflow: !isJoinTheBetaBigger && "scroll" }}>
      <ThemeProvider theme={theme}>
        <input style={{ display: "none" }} type="password" autoComplete="current-password" />

        <form className="signup" onSubmit={handleSubmit}>
          {trail.map((style, index) => (
            <animated.div
              key={index}
              style={index === elements.length - 1 ? { position: "relative", display: "contents", flexGrow: 1, bottom: 0, left: 0, ...style } : style}>
              {elements[index]}
            </animated.div>
          ))}
          <TermsAndConditionsDialog open={showTerms} onCloseDialog={handleCloseTerms} onAccept={handleDialogAcceptTerms} showAcceptButton={true} />
        </form>
      </ThemeProvider>
    </footer>
  )
}

export default FooterLandingPage
