import appLogo from "../images/logo/appLogo.svg"

import "./installLandingPage.css"
import logoColor from "./logoColor"
import { ThemeProvider } from "@mui/material"
import theme from "../../../asset/theme"
import MDButton from "../../../components/MDButton"
import { Link } from "react-router-dom"
import rightArrow from "../../../icons/rightArrow"
import Footer from "../../../components/Footer"
import "./../index.css"
import "./link.css"

const InstallLandingPage = () => {
  return (
    <about is="webview" class="installpage">
      <logo is="webview">{logoColor}</logo>
      <ThemeProvider theme={theme}>
        <install>
          <img width="128" height="128" className="appLogo" src={appLogo} alt="MyDigitalEstate Logo Mark" />
          <texts>
            <h1>You successfully created a beta account.</h1>
            <p>Install the app by tapping the button below.</p>
          </texts>
          <useraction>
            <MDButton
              className="progressier-install-button"
              variant="gradient"
              color="info"
              fullWidth
              data-install="Install now"
              data-hide-standalone="false"
              type="submit"
              sx={{
                width: "205px",
                height: "53px",
                textTransform: "none",
                px: "20px",
                borderRadius: "4.375rem",
                fontWeight: 500,
                fontSize: "16px"
              }}>
              Install now{" "}
            </MDButton>
            <Link className="install-spacer" to="/tutorial/1" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              or do this later {rightArrow}
            </Link>
          </useraction>
        </install>
        <Footer developerName={"Futuri ENT"} color="#7B809A" display="none" nameAppColor="#344767" opacity="1" />
      </ThemeProvider>
    </about>
  )
}
export default InstallLandingPage
