import house from "../images/landing.webp"
import "./poster.css"

const poster = (
  <poster is="webview">
    <img
      src={house}
      alt="MyDigitalEstate Cover"
      style={{
        backgroundColor: "rgb(160 147 144)",
        backgroundImage:
          "url(data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAKD1eQGSMU3upV3OSXXCLa4uxcpjIX3qijIiMio+ceIOcaGeA)",
        backgroundSize: "cover"
      }}
    />
    <texts is="webview">
      <h1>The real estate market is waiting only for you</h1>
      <p>
        Don’t let this opportunity slip away. <br /> Claim your space with MyDigitalEstate.
      </p>
    </texts>
  </poster>
)
export default poster
