import { Card } from "@mui/material"
import MDBox from "./MDBox"
import { Logo } from "../logos/logo"
import MDTypography from "./MDTypography"

const isMobile = "ontouchstart" in document.documentElement

export const noLandscape = (
  <MDBox
    className="nolandscape"
    sx={{
      justifyContent: "center",
      zIndex: 1991,
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      height: "100%",
      background: "#f4f6f8",
      display: "none"
    }}>
    <Card sx={{ maxWidth: "600px", margin: "auto" }}>
      <MDBox display="flex" justifyContent="center">
        <Logo width="100px" height="100px" />
      </MDBox>
      <MDBox py={1} pb={4} px={3} display="flex" flexDirection="column" gap={3} textAlign="center">
        <MDTypography display="block" variant="h5" sx={{ color: "info" }} py={0}>
          {isMobile ? "Please turn your device in vertical mode" : "The application is available only on mobile phones."}
        </MDTypography>
        <MDTypography fontSize={15} display="block" variant="text1" sx={{ color: "info", lineHeight: 0.2 }} py={0}>
          {isMobile ? "This applications is not yet available for landscape mode." : "Resize your window to enjoy the app from desktop."}
          <br />
        </MDTypography>
      </MDBox>
    </Card>
  </MDBox>
)
