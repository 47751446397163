import { Link, useNavigate } from "react-router-dom"

// @mui material components
import Card from "@mui/material/Card"
import Checkbox from "@mui/material/Checkbox"

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox"
import MDTypography from "../../components/MDTypography"
import MDInput from "../../components/MDInput"
import MDButton from "../../components/MDButton"
// Authentication layout components

// Images
import { useState } from "react"
import { supabase } from "../../supabaseClient"
import { useToast } from "@dalgu/react-toast"
import { FormControlLabel, FormGroup } from "@mui/material"
import { TermsAndConditions } from "./TermsAndConditions"
import TermsAndConditionsDialog from "./TermsAndConditionDialog"
import logo from "../../logos"

function RegisterPage({ inviter, updateSessionFunction }) {
  const { showToast } = useToast()
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [invited_by, setInviter] = useState(inviter)
  const [password, setPassword] = useState("")
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClickOpenTerms = () => {
    setShowTerms(prevState => {
      return true
    })
  }

  const handleCloseTerms = () => {
    setShowTerms(prevState => {
      return false
    })
  }

  const handleDialogAcceptTerms = () => {
    setAcceptTerms(true)
    setShowTerms(false)
  }
  const handleAcceptTerms = () => {
    setAcceptTerms(!acceptTerms)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const { data: usernames, error: usernameError } = await supabase.from("profiles_view").select("username")

    const username = usernames.map(user => user.username)
    if (username?.includes(name)) {
      showToast("Username already exists. Please choose a different username.")
      return
    }

    if (invited_by !== "" && !username?.includes(invited_by)) {
      showToast("Please get a valid invitation.")
      return
    } else {
      setInviter(invited_by)
    }

    if (usernameError) {
      showToast(usernameError.message)
      return
    }

    const { data: emailData, error: emailError } = await supabase.from("profiles_view").select("email").eq("email", email)

    if (emailError) {
      showToast(emailError.message)
      return
    }

    if (emailData?.length > 0) {
      showToast("Email already exists. Please choose a different email.")
      return
    }
    const { error: registrationError } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: { username: name, invited_by: invited_by || inviter, firstroute: "/tutorial/1" }
      }
    })
    //delay end of loading to prevent double click
    setTimeout(() => {
      setLoading(false)
    }, 2000)

    const createNewCitizenNotification = async () => {
      try {
        const { data, error } = await supabase.rpc("new_citizen_notification", {
          invited_by: invited_by,
          username: name
        })
        if (error) {
          showToast(error.message)
        } else {
          console.log("Notification created successfully:", data)
        }
      } catch (error) {
        showToast("An error occurred while creating the notification")
        console.error(error)
        throw error // Rethrow the error
      }
    }

    if (!registrationError) {
      let { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password
      })

      if (error) {
        showToast(error.message)
      } else {
        updateSessionFunction(data.user)
        await createNewCitizenNotification()
        navigate("/market")
      }
    }

    if (registrationError !== null) {
      if (registrationError.message === 'new row for relation "profiles" violates check constraint "username_blacklist_constraint"') {
        showToast("The username you are trying to use is not available. Please try another one!")
      } else if (registrationError.message === 'new row for relation "profiles" violates check constraint "username_constraint"') {
        showToast("Username length cannot be longer than 20 characters")
      } else {
        showToast(registrationError.message)
      }
    }
  }
  return (
    <Card sx={{ maxWidth: "400px", m: "auto", width: "100%" }}>
      <MDBox px={2} pt={2}>
        {logo}
      </MDBox>
      <MDBox pt={5} pb={3} px={3}>
        <MDBox component="form" role="form" onSubmit={handleSubmit}>
          <MDBox mb={2}>
            <MDInput
              data-test-id="input-register-username"
              type="text"
              label="Username"
              variant="standard"
              autoFocus
              fullWidth
              onChange={event => {
                const input = event.target.value.toLowerCase().trim()
                const regularExpression = /^[a-z0-9]+$/
                if (regularExpression.test(input) || input === "") {
                  setName(input)
                }
              }}
              value={name}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              data-test-id="input-register-email"
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              onChange={event => setEmail(event.target.value.trim())}
              value={email}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              data-test-id="input-register-inviter"
              type="inviter"
              label="Inviter"
              helperText="Who invited you to the platform?"
              variant="standard"
              fullWidth
              onChange={event => setInviter(event.target.value.toLowerCase().trim())}
              value={invited_by ?? inviter}
              required
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              data-test-id="input-register-password"
              type="password"
              label="Password"
              variant="standard"
              fullWidth
              onChange={event => setPassword(event.target.value)}
              value={password}
              autoComplete="new-password"
            />
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="center" ml={-1} onChange={handleAcceptTerms}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={acceptTerms} data-test-id="checkbox-register-checkbox" />}
                label={
                  <>
                    <MDBox sx={{ display: "flex" }}>
                      <MDTypography variant="button" fontWeight="regular" color="text" sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                        &nbsp;&nbsp;I agree the&nbsp;
                      </MDTypography>
                      <TermsAndConditions terms={"Terms"} onClick={handleClickOpenTerms} webkitTextFillColor="#1a73e8" />
                    </MDBox>
                  </>
                }
              />
            </FormGroup>
            <TermsAndConditionsDialog open={showTerms} onCloseDialog={handleCloseTerms} onAccept={handleDialogAcceptTerms} showAcceptButton={true} />
          </MDBox>
          <MDBox mt={3} mb={1}>
            <MDButton
              variant="gradient"
              data-test-id="btn-register"
              color="info"
              fullWidth
              type="submit"
              disabled={!acceptTerms || !name || !email || !invited_by || !password || loading}>
              Register
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Already have an account?{" "}
              <MDTypography component={Link} to="/login" variant="button" color="info" fontWeight="medium" textGradient>
                Login here
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default RegisterPage
