import React, { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { MaterialUIControllerProvider } from "./components/context"
import { ToastProvider } from "@dalgu/react-toast"
import AppContainer from "./AppContainer"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <ToastProvider duration={2} zIndex={100}>
          <AppContainer />
        </ToastProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
