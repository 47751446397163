import React from "react"
import MDTypography from "../../components/MDTypography"

export const TermsAndConditions = ({ pt, fontSize, onClick, terms, termsColor, borderRadius, fontWeight, lineHeight, webkitTextFillColor }) => {
  const customStyles = {
    backgroundImage: "none",
    WebkitTextFillColor: webkitTextFillColor || "#344767 !important"
  }
  return (
    <MDTypography
      className="terms-conditions"
      component="a"
      href="#"
      variant="button"
      fontWeight="bold"
      style={{
        fontSize: fontSize || "0.875rem",
        webkitTextFillColor,
        color: termsColor || "red !important",
        borderRadius: borderRadius,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        pt: pt
        // ...customStyles
      }}
      sx={customStyles}
      textGradient
      onClick={onClick}
      data-test-id="dialog-register-open-terms">
      {terms} and conditions
    </MDTypography>
  )
}
