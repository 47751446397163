import { animated, useSpring } from "@react-spring/web"
import "./join-the-beta-button.css"

const JoinTheBetaButton = ({ opened, onClick }) => {
  const scaleAnimation = useSpring({
    from: { scale: 1 },
    to: { scale: opened ? 15 : 1 },
    config: opened ? buttonAnimationsInConfig : buttonAnimationsOutConfig
  })

  const colorAnimation = useSpring({
    from: { color: "#fefefe" },
    to: { color: opened ? "#007AFF" : "#fefefe" },
    config: opened ? textAnimationsInConfig : buttonAnimationsOutConfig
  })

  return (
    <animated.button onClick={onClick} style={{ ...scaleAnimation, ...colorAnimation, pointerEvents: opened && "none" }}>
      Join the beta
    </animated.button>
  )
}
export default JoinTheBetaButton
const buttonAnimationsInConfig = { mass: 1, friction: 15, tension: 150 }
const buttonAnimationsOutConfig = { mass: 1, friction: 30, tension: 300 }
const textAnimationsInConfig = { mass: 0.1, friction: 15, tension: 305, clamp: true }
