import { Suspense, lazy, useEffect, useState } from "react"
import theme from "./asset/theme"
import { ThemeProvider } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import "./asset/css/global.css"
import { useLocation, useNavigate } from "react-router-dom"
import { supabase } from "./supabaseClient"
import LoginPage from "./pages/login"
import RegisterPage from "./pages/register"
import { AuthContext } from "./components/context"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./components/Suspense/ErrorBoundary/ErrorFallback"
import LogoLoading from "./components/Suspense/CoverLoading/LogoLoading"
import { noLandscape } from "./components/NoLandscape"
import AboutPage from "./pages/about"
import InstallLandingPage from "./pages/about/components/installLandingPage"

const App = lazy(() => import("./App"))
function AppContainer() {
  const [userState, setUserState] = useState()
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()
  const [profileData, setProfileData] = useState()
  const [showLandingPage, setShowLandingPage] = useState(false)
  const queryParameters = new URLSearchParams(window.location.search)
  const encryptedAuthToken = queryParameters.get("a")

  useEffect(() => {
    async function getUser() {
      setShowLandingPage(false)
      //if we are not on safari we can load manifest.json now
      if (!navigator.userAgent.includes("Safari")) {
        document.querySelector("#manifest-placeholder").setAttribute("href", "/manifest.json")
      }
      if (encryptedAuthToken) {
        // Convert the Base64 URL-safe string back to binary data
        const base64Data = encryptedAuthToken
          .replace(/-/g, "+")
          .replace(/_/g, "/")
          .padEnd(encryptedAuthToken.length + ((4 - (encryptedAuthToken.length % 4)) % 4), "=")
        const jsonDataDecoded = new Uint8Array([...atob(base64Data)].map(char => char.charCodeAt(0)))

        // Decode the binary data back to a JSON string
        const decoder = new TextDecoder()
        const jsonString = decoder.decode(jsonDataDecoded)

        // Parse the JSON string into a JavaScript object
        const decryptedData = JSON.parse(jsonString)

        localStorage.setItem("sb-lcxmxvpzbenzvgpxnent-auth-token", decryptedData)
      }
      const {
        data: { session }
      } = await supabase.auth.getSession()
      setLoading(false)
      if (session) {
        setUserState(session.user)
      } else {
        if (pathname.substring(1) && !["dashboard", "register"].includes(pathname.substring(1))) checkUsername()
        if (pathname === "/") setShowLandingPage(true)
      }
    }
    getUser()
  }, [pathname])

  async function checkUsername() {
    setLoading(true)
    const { data: profileData } = await supabase.from("profiles_view").select("username").eq("username", pathname.substring(1)).single()
    setProfileData(profileData)
    setLoading(false)
  }
  const [boundaryError, setBoundaryError] = useState()
  const showError = error => {
    setBoundaryError(error.message)
  }
  if (loading) return <LogoLoading />
  if (pathname === "/config") return <InstallLandingPage />
  return showLandingPage ? (
    <AboutPage updateSessionFunction={setUserState} />
  ) : (
    <ThemeProvider theme={theme}>
      {noLandscape}
      <CssBaseline />
      <AuthContext.Provider value={userState}>
        {userState ? (
          <ErrorBoundary fallback={<ErrorFallback error={boundaryError} />} onError={showError}>
            <Suspense fallback={<LogoLoading />}>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </Suspense>
          </ErrorBoundary>
        ) : pathname === "/register" || pathname === `/${profileData?.username}` ? (
          <div style={{ padding: "0 min(5vw,16px)", height: "100%" }}>
            <RegisterPage updateSessionFunction={setUserState} inviter={profileData?.username} />
          </div>
        ) : (
          <div style={{ padding: "0 min(5vw,16px)", height: "100%" }}>
            <LoginPage updateSessionFunction={setUserState} />
          </div>
        )}
      </AuthContext.Provider>
    </ThemeProvider>
  )
}
export default AppContainer
