import PropTypes from "prop-types"
import MDBox from "../../components/MDBox"
import MDTypography from "../../components/MDTypography"
import typography from "../../asset/theme/base/typography"
import { Grid } from "@mui/material"
import { TermsAndConditions } from "../../pages/register/TermsAndConditions"
import TermsAndConditionsDialog from "../../pages/register/TermsAndConditionDialog"
import { useState } from "react"
function Footer({ width, company, developerName, data, color, opacity, display, nameAppColor, opacityAppName, footerMarginBottom, backgroundColor }) {
  const { href, name } = company
  const { size } = typography
  const [showTerms, setShowTerms] = useState(false)
  const handleOpenTerms = () => {
    setShowTerms(true)
  }
  const handleCloseTerms = () => {
    setShowTerms(false)
  }
  return (
    <Grid
      item
      xs={12}
      md={6}
      xl={3}
      flexDirection="column"
      className="footer"
      sx={{
        width: width,
        opacity: opacity || 0.66,
        marginBottom: footerMarginBottom || "calc(env(safe-area-inset-bottom) + 0.5rem)",
        backgroundColor: backgroundColor || "#fff"
      }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" color={color || "text"} fontSize={size.sm} px={1.5}>
        Copyright &copy; {data} &nbsp;
        <MDTypography variant="button" fontWeight="medium" sx={{ color: nameAppColor || "gradient", opacity: opacityAppName }}>
          MyDigitalEstate
        </MDTypography>
      </MDBox>
      <MDBox sx={{ fontSize: "12px", margin: 0, padding: 0, display: display || "flex", alignItems: "center", justifyContent: "center" }}>
        <TermsAndConditions terms={"Terms"} fontSize="12px" onClick={handleOpenTerms} webkitTextFillColor="#1a73e8" textGradient="none" />
      </MDBox>
      <TermsAndConditionsDialog open={showTerms} onCloseDialog={handleCloseTerms} showAcceptButton={false} />
      <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" color={color || "text"} fontSize={10} px={1.5}>
        Developed by {developerName}
      </MDBox>
      <br />
      <br />
    </Grid>
  )
}

Footer.defaultProps = {
  company: { href: "https://www.creative-tim.com/", name: "MyDigitalEstate" }
}
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object)
}

export default Footer
