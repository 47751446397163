import logomarkcolor from "../images/logo/logomarkcolor.svg"
import logotextcolor from "../images/logo/logotextcolor.svg"
import "./logo.css"

const logoColor = (
  <>
    <img className="logomark" src={logomarkcolor} alt="MyDigitalEstate Logo Mark" />
    <img className="logotext" src={logotextcolor} alt="MyDigitalEstate Logo Text" />
  </>
)
export default logoColor
